@import url('https://fonts.googleapis.com/css2?family=Jockey+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body {
    background-color: #101010 !important;
}

button {
    display: flex;
    align-items: center;
    border-radius:10px !important;
    gap: 10px;
    cursor: pointer;
    font-size: 1rem;
    background-color: transparent;
    padding: 10px 16px;
    outline: transparent;
    border: none;
    color: white;
}

* {
    color: rgba(255, 255, 255, 0.87);
}